import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './feature.css';

const API_URL = "https://api.badskullgaming.com/api/get-featurePlatform";
const IMAGE_PATH = "https://api.badskullgaming.com/uploads/platform";

const Feature = () => {
  const [platforms, setPlatforms] = useState([]);

  const headingColor = {
    color: "#EB030",
  }

  // Fetch data from API
  useEffect(() => {
    const fetchPlatforms = async () => {
      try {
        const response = await fetch(API_URL);
        const data = await response.json();
        if (data.status === 200) {
          setPlatforms(data.FeaturePlatforms);
        } else {
          console.error("Error fetching data", data);
        }
      } catch (error) {
        console.error("API call failed", error);
      }
    };
    fetchPlatforms();
  }, []);

  return (
    <section className="featured-section container-fluid">
      <div className="container">
        <div className="row">
          <h2 className="fw-bold mb-4 heading__color">Featured Platforms</h2>

          {/* Rendering platform cards dynamically */}
          {platforms.map((platform) => (
            <div key={platform.id} className="col-6 col-md-4 col-lg-2 mb-4">
              <Link to='/Deposit'>
                <div className="platform-card">
                  <img
                    className="img-fluid"
                    src={`${IMAGE_PATH}/${platform.image}`}
                    alt={`Platform ${platform.id}`}
                  />
                </div></Link>
            </div>
          ))}
        </div>
      </div>
    </section >
  );
};

export default Feature;
