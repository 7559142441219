import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "../Sweepstakes/Sweepstakes.css";


const RefundPolicy = () => {
    return (
        <>
            <Header />
            <div className="Sweepstakes_bg">
                <div className="container py-5   text-white mx-auto px-4  max-w-3xl">
                    <h1 className="text-3xl font-bold text-center mb-6">Refund Policy</h1>
                    <p className="text-gray-600 mb-4">Effective Date: May 12th, 2024</p>

                    <div className="prose">
                        <p className="mb-6">
                            At BadSkull.com, we aim to provide a transparent and fair policy regarding refunds and chargebacks.
                            Please read the following carefully to understand our terms.
                        </p>

                        <h4 className="text-2xl font-semibold mt-6 mb-4">Refunds</h4>
                        <p className="mb-4">
                            As a sweepstakes website, we do not offer refunds for entries or purchases.
                            All transactions on our platform are considered final.
                        </p>

                        <h4 className="text-2xl font-semibold mt-6 mb-4">Chargebacks</h4>
                        <p className="mb-4">
                            In the event of a chargeback initiated by the customer, BadSkull.com reserves the right to suspend or ban the associated account.
                        </p>
                        <p className="mb-4">
                            Chargebacks can lead to additional fees, and any costs incurred by BadSkull.com as a result of the chargeback
                            will be the responsibility of the account holder.
                        </p>

                        <h4 className="text-2xl font-semibold mt-6 mb-4">Account Suspension</h4>
                        <p className="mb-4">
                            If a chargeback is initiated, BadSkull.com may suspend the associated account pending investigation.
                        </p>
                        <p className="mb-4">
                            During this period, the account holder may be restricted from participating in current or future sweepstakes.
                        </p>

                        <h4 className="text-2xl font-semibold mt-6 mb-4">Contact Information</h4>
                        <p className="mb-2">
                            If you have any questions or concerns about our refund and chargeback policy, please contact our customer service team:
                        </p>
                        <ul className="list-none mb-4">
                            <li>Email: <a href="mailto:support@BadSkull.com" className="text-blue-600">support@BadSkull.com</a></li>
                            <li>Phone: <a href="tel:+14704855462" className="text-blue-600">+1-470-485-5462</a></li>
                        </ul>

                        <h4 className="text-2xl font-semibold mt-6 mb-4">Changes to the Policy</h4>
                        <p className="m-0">
                            BadSkull.com reserves the right to update, change, or modify this policy at any time.
                            Any changes will be effective immediately upon posting on our website.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default RefundPolicy;
