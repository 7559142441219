import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import CardSection from "../../components/CardSection";

const Deposit = () => {
  return (
    <>
      <Header />
      <CardSection />
      <Footer />
    </>
  )
}

export default Deposit;
