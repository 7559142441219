import React, { useState } from 'react';
import img01 from '../../images/logowithupperimg.png';
import './ContactUs.css';
import { Link } from 'react-router-dom';

const API_URL = "https://badskullgaming.com/api/store-contact";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    message: '',
  });

  const [responseMessage, setResponseMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResponseMessage('');

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          full_name: formData.fullName,
          email: formData.email,
          message: formData.message,
        }),
      });

      const data = await response.json();

      if (data.status === 200) {
        setResponseMessage('Your message was sent successfully!');
        // Clear the form after successful submission
        setFormData({
          fullName: '',
          email: '',
          message: '',
        });
      } else {
        setResponseMessage('Failed to send your message. Please try again.');
      }
    } catch (error) {
      console.error('Error during form submission:', error);
      setResponseMessage('An error occurred. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <main>
      <section className="unique-contact-section d-flex align-items-center justify-content-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 text-center d-flex flex-column justify-content-center">
              <h2 className="unique-contact-heading">Need Help?</h2>
              <p className="unique-contact-description mt-4 text-center">
                For assistance, please email us at{' '}
                <Link to="mailto:support@badskull.com">
                  support@badskulgaming.com
                </Link>
                . We strive to respond as soon as possible, though it may take up to 24 hours. For instant LIVE support, use Facebook Messenger.
              </p>
              <a
                href="https://m.me/badskullgames"
                target="_blank"
                rel="noopener noreferrer"
                className="btn unique-btn-messenger mt-5 align-self-center d-flex align-items-center justify-content-center"
              >
                <i className="fab fa-facebook-messenger mr-2"></i> 
                <span style={{ marginLeft: '8uppx' }}>Messenger</span>
              </a>
            </div>
            <div className="col-md-6 d-none d-md-block text-center panda-house">
              <img
                src={img01}
                alt="Panda's Playhouse Logo"
                className="unique-logo-image"
              />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default ContactUs;
