import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import '../Sweepstakes/Sweepstakes.css'

const AmlPolicy = () => {
    return (
        <>
            <Header />
            <div className="Sweepstakes_bg">
                <div className="container text-light py-5">
                    <h1 className="text-center mb-4">Anti-Money Laundering (AML) Policy</h1>
                    <div className="mb-4">
                        <p>
                            Welcome to <strong>The Bad Skull</strong>. This Anti-Money Laundering (AML) Policy outlines our commitment to
                            detecting and preventing illegal activities, including money laundering and terrorist financing.
                        </p>
                        <p>
                            As part of our efforts to provide a safe and compliant gaming environment, we strictly adhere to all
                            applicable regulations and require all users of our platform to comply with these rules.
                        </p>
                    </div>

                    <div className="mb-4">
                        <h2>1. Purpose of the Policy</h2>
                        <p>This policy aims to ensure that "The Bad Skull" remains free of any criminal activity by:</p>
                        <ul className="">
                            <li className="list-group-item">Preventing and detecting money laundering activities.</li>
                            <li className="list-group-item">Ensuring compliance with all applicable AML laws and regulations.</li>
                            <li className="list-group-item">Protecting the reputation and integrity of our platform.</li>
                        </ul>
                    </div>

                    <div className="mb-4">
                        <h2>2. User Verification</h2>
                        <p>
                            All users must complete a verification process to use our platform, which includes:
                        </p>
                        <ul className="">
                            <li className="list-group-item">Providing valid identification documents.</li>
                            <li className="list-group-item">Confirming payment methods and addresses.</li>
                            <li className="list-group-item">Verifying identity through secure checks.</li>
                        </ul>
                    </div>

                    <div className="mb-4">
                        <h2>3. Monitoring and Reporting</h2>
                        <p>
                            "The Bad Skull" actively monitors user activity on the platform to identify suspicious transactions or
                            behaviors. Any such activity will be reported to relevant authorities in compliance with applicable laws.
                        </p>
                    </div>

                    <div className="text-center mt-5">
                        <p>
                            If you have any questions regarding this policy, please contact our support team at{' '}
                            <a href="mailto:support@thebadskull.com">support@thebadskull.com</a>.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default AmlPolicy;
