import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./ProductComponent.css";
import Feature from "../Feature";

const CREDIT_API_URL = `https://api.badskullgaming.com/api/get-deposit`;
const API_URL = "https://api.badskullgaming.com/api/get-credits";

const ProductComponent = () => {
  const [credits, setCredits] = useState(10); // Default 15 credits
  const [quantity, setQuantity] = useState(1);
  const [selectedPrice, setSelectedPrice] = useState(10.0); // Default price
  const cardId = new URLSearchParams(window.location.search).get("id");

  const [cardData, setCardData] = useState({});
  const [imagePath, setImagePath] = useState("");

  // Fetch card data
  const fetchCardData = async () => {
    try {
      const response = await fetch(`${CREDIT_API_URL}?id=${cardId}`);
      const data = await response.json();
      const card = data.deposit.find((val) => val.id === parseInt(cardId));
      if (data.status === 200 && card) {
        setCardData(card);
        setImagePath(data.imagePath);
      } else {
        console.log("No matching card data found.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchCardData();
  }, []);

  // Handle credit input change
  const handleCreditChange = (e) => {
    const enteredCredits = parseInt(e.target.value) || 0; // Ensure it's a number
    setCredits(enteredCredits);
    setSelectedPrice(enteredCredits); // Update price as 1 credit = $1
  };

  const handleQuantityChange = (e) => {
    setQuantity(parseInt(e.target.value) || 1); // Ensure it's a number
  };

  const selectedCredit = {
    name: cardData.title,
    number: credits,
    credits: quantity,
    price: selectedPrice * quantity,
  };

  localStorage.setItem("credits", JSON.stringify(selectedCredit));

  return (
    <>
      <div className="ProductComponent_main">
        <div className="container my-5">
          <div className="row">
            {/* Image Section */}
            <div className="col-md-6 col-12 mb-4">
              <div className="position-relative">
                <img
                  src={`${imagePath}/${cardData.image}`}
                  alt={cardData.title}
                  className="img-fluid rounded"
                />
              </div>
            </div>

            {/* Details Section */}
            <div className="col-md-6 col-12">
              <h3 className="fw-bold">{cardData.title}</h3>
              <p className="text-secondary">Mininum 5$</p>

              {/* Credit Input */}
              <label htmlFor="credits" className="form-label fw-bold">
                Credits
              </label>
              <input
                type="number"
                id="credits"
                className="form-control mb-3"
                min="0"
                value={credits}
                onChange={handleCreditChange}
              />

              {/* Display Calculated Price */}
              <p className="fs-4 fw-bold">{`$${selectedPrice * quantity}.00`}</p>

              {/* Quantity Input and Add to Cart Button */}
              <div className="d-flex align-items-center mb-3">
                <input
                  type="number"
                  min="1"
                  className="form-control me-3"
                  value={quantity}
                  onChange={handleQuantityChange}
                  style={{ maxWidth: "80px" }}
                />
                <Link to={`/CheckoutForm?title=${encodeURIComponent(cardData.title)}`}>
                  <button className="btn btn-custom text-white fw-bold">
                    BUY NOW
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <Feature />
      </div>
    </>
  );
};

export default ProductComponent;
