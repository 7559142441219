import React, { useEffect } from "react";
import "./Nabar.css";
import { NavLink, useLocation } from 'react-router-dom';
import logo from '../../images/logowithsideimg.png';

const Header = () => {
  const location = useLocation();
  useEffect(() => {
    // Reset the body styles on every route change
    document.body.style.overflow = "scroll";  // Reset overflow
    document.body.classList.remove("offcanvas-active"); // Remove any Bootstrap offcanvas related styles
    document.body.style.paddingRight = "";  // Reset padding-right caused by offcanvas backdrop

    // If you need to remove more styles, you can add them here (e.g., backdrop-related styles)
    // document.body.classList.remove("modal-open"); // If modals affect the body
  }, [location]);  // This effect runs every time the location (route) changes
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container-fluid">
          <NavLink className="navbar-brand p-0 fs-3" to="/">
            <img src={logo} alt="Logo" height={'80px'} />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#navbarOffcanvas"
            aria-controls="navbarOffcanvas"
            // aria-expanded="false"
            aria-label="Toggle navigation"
            style={{
              overflow: "scroll !important"
            }}
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div
            className="offcanvas offcanvas-start"
            style={{
              maxWidth: "90%",
            }}
            tabIndex="-1"
            id="navbarOffcanvas"
          >
            <div className="offcanvas-header bg-dark">
              <NavLink className="navbar-brand p-0 fs-3" to="/">
                <img src={logo} alt="Logo" height={'60px'} style={{ height: "80px" }} />
              </NavLink>
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body bg-dark">
              <ul className="navbar-nav ms-md-auto text-start">
                {/* Home Link (default active) */}
                <NavLink
                  to="/"
                  end
                  className={({ isActive }) =>
                    isActive ? "btn nav-link active px-2 text-start" : "btn nav-link px-2 text-start"
                  }
                >
                  Home
                </NavLink>

                {/* Other Links */}
                <NavLink
                  to="/Deposit"
                  className={({ isActive }) =>
                    isActive ? "btn nav-link active px-2 text-start" : "btn nav-link px-2 text-start"
                  }
                >
                  Deposit
                </NavLink>
                <NavLink
                  to="/Support"
                  className={({ isActive }) =>
                    isActive ? "btn nav-link active px-2 text-start" : "btn nav-link px-2 text-start"
                  }
                >
                  Support
                </NavLink>
                <NavLink
                  to="/Faqs"
                  className={({ isActive }) =>
                    isActive ? "btn nav-link active px-2 text-start" : "btn nav-link px-2 text-start"
                  }
                >
                  FAQs
                </NavLink>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;

