import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ContactUs from "../../components/ContactUs";

const Support = () =>{
    return(
        <>
        <Header/>
        <ContactUs/>

        <Footer/>

        </>
    )
}

export default Support;