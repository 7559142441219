import React from "react";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import FAQs from "../../components/FAQs";

const Faqs = () => {
    return (
        <>
            <Header/>
            <FAQs />
            <Footer />
        </>
    )
}

export default Faqs;