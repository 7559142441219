import React from 'react';
import '../Sweepstakes/Sweepstakes.css'
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const ResponsibleSocialGameplay = () => {
    return (
        <>
            <Header />
            <div className="Sweepstakes_bg">
                <div className="container text-light py-5">
                    <h1 className="text-center mb-4">Responsible Social Gameplay</h1>
                    <p>
                        At <strong>Our Gaming Platform</strong>, we are committed to promoting a healthy and enjoyable gaming experience.
                        We believe that social gameplay should be entertaining and conducted responsibly. This page outlines our principles
                        and guidelines for ensuring responsible gaming.
                    </p>

                    <div className="mb-4">
                        <h3>1. Our Commitment</h3>
                        <p>
                            We strive to create a gaming environment that is safe, inclusive, and free from harmful behaviors. Our commitment includes:
                        </p>
                        <ul className=" mb-3">
                            <li className="list-group-item">Encouraging positive interactions among players.</li>
                            <li className="list-group-item">Providing tools to manage playtime and spending.</li>
                            <li className="list-group-item">Actively monitoring and addressing inappropriate behavior.</li>
                        </ul>
                    </div>

                    <div className="mb-4">
                        <h3>2. Guidelines for Players</h3>
                        <p>
                            We encourage all players to follow these guidelines to ensure a responsible and enjoyable experience:
                        </p>
                        <ul className=" mb-3">
                            <li className="list-group-item">Treat others with respect and avoid harmful language.</li>
                            <li className="list-group-item">Set time and spending limits to maintain a balanced lifestyle.</li>
                            <li className="list-group-item">Report any abusive or inappropriate behavior to our support team.</li>
                        </ul>
                    </div>

                    <div className="mb-4">
                        <h3>3. Tools and Resources</h3>
                        <p>
                            To support responsible gameplay, we offer tools and resources such as:
                        </p>
                        <ul className=" mb-3">
                            <li className="list-group-item">Playtime reminders and session limits.</li>
                            <li className="list-group-item">Account spending trackers and limits.</li>
                            <li className="list-group-item">Support resources for addressing gaming-related challenges.</li>
                        </ul>
                    </div>

                    <div className="mb-4">
                        <h3>4. Contact Support</h3>
                        <p>
                            If you need assistance or have concerns about your gaming habits, please reach out to our support team:
                        </p>
                        <ul className="list-unstyled">
                            <li>
                                <strong>Email:</strong> <a href="mailto:support@gamingplatform.com">support@gamingplatform.com</a>
                            </li>
                            <li>
                                <strong>Phone:</strong> +1-123-456-7890
                            </li>
                        </ul>
                    </div>

                    <div className="mb-4">
                        <h3>5. Changes to Our Policy</h3>
                        <p>
                            We reserve the right to update or modify our Responsible Social Gameplay policy at any time. Any changes will
                            be communicated promptly through this page.
                        </p>
                    </div>

                    <div className="text-center mt-5">
                        <p>
                            Thank you for being a part of our gaming community and for contributing to a positive and responsible gaming environment.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ResponsibleSocialGameplay;
