import React from "react";
import Header from "../../components/Header";
import Hero from "../../components/Hero";
import Feature from "../../components/Feature";
import Steps from "../../components/Steps";
import Pandasection from "../../components/PandaSection";
import Footer from "../../components/Footer";

const Home = () => {
  return (
    <>
      <Header />
      <Hero />
      <Feature />
      <Steps />
      <Pandasection />
      <Footer />
    </>
  )
}
export default Home;
