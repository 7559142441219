import Footer from "../../components/Footer"
import Header from "../../components/Header"
import BitcoinPayment from "../../components/BitcoinPayment"

export default function index() {
  return (
    <>
      <BitcoinPayment />
    </>
  )
}
