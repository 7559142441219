import React, { useState } from "react";
import { Link } from "react-router-dom";
import './ShoppingCart.css'
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const ShoppingCart = () => {
  const [cartItems, setCartItems] = useState([
    {
      id: 1,
      name: "Golden Dragon - 50 Credits",
      price: 50.0,
      quantity: 1,
      image: "https://via.placeholder.com/50",
    },
    {
      id: 2,
      name: "Vegas X - 20 Credits",
      price: 20.0,
      quantity: 3,
      image: "https://via.placeholder.com/50",
    },
    {
      id: 3,
      name: "Golden Dragon - 15 Credits",
      price: 15.0,
      quantity: 1,
      image: "https://via.placeholder.com/50",
    },
  ]);

  const handleQuantityChange = (id, quantity) => {
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id ? { ...item, quantity: quantity } : item
      )
    );
  };

  const handleRemoveItem = (id) => {
    setCartItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  const calculateSubtotal = () =>
    cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

  return (
    <>
      <Header />
      <div className="shopping_main">
        <div className="container my-4">
          {/* Notification Bar */}
          <div className="alert alert-light border rounded-pill text-dark d-flex justify-content-between align-items-center">
            <span>"Golden Dragon" has been added to your cart.</span>
            <a className="btn btn-link text-decoration-none">
              Continue shopping
            </a>
          </div>

          {/* Shopping Cart Table */}
          <div className="table-responsive">
            <table className="table table-bordered align-middle">
              <thead className="bg-warning text-white">
                <tr>
                  <th>Product</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Subtotal</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {cartItems.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <div className="d-flex align-items-center">
                        <img
                          src={item.image}
                          alt={item.name}
                          className="me-3 rounded"
                        />
                        <span>{item.name}</span>
                      </div>
                    </td>
                    <td>${item.price.toFixed(2)}</td>
                    <td>
                      <input
                        type="number"
                        className="form-control w-50"
                        min="1"
                        value={item.quantity}
                        onChange={(e) =>
                          handleQuantityChange(item.id, parseInt(e.target.value))
                        }
                      />
                    </td>
                    <td>${(item.price * item.quantity).toFixed(2)}</td>
                    <td>
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => handleRemoveItem(item.id)}
                      >
                        ×
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>



          {/* Cart Totals */}
          <div className="row mt-5">
            <div className="col-md-6 offset-md-6">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <th>Subtotal</th>
                    <td>${calculateSubtotal().toFixed(2)}</td>
                  </tr>
                  <tr>
                    <th>Total</th>
                    <td>${calculateSubtotal().toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
              <Link to="/CheckoutForm">
                <button className="btn btn-custom w-100 fw-bold">
                  Proceed to Checkout
                </button>

              </Link>

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ShoppingCart;
